import LocalizedLink from '@components/Localization/LocalizedLink'
import { NewAtGassan } from '@components/NewAtGassan'
import { Button } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

export const CreateAccountEnterEmail: FC = () => {
  const { t } = useTranslation(['account', 'other', 'navigation'])

  return (
    <div
      className={cn(
        'mt-6 block items-center justify-between self-center',
        'sm:mt-12',
        'lg:mt-0 lg:pl-12',
        'xl:pl-24',
      )}
    >
      <NewAtGassan />
      <LocalizedLink href={t('account.create.url', { ns: 'navigation' })}>
        <Button as="link" variant="dark">
          {t('becomeMember', { ns: 'account' })}
        </Button>
      </LocalizedLink>
    </div>
  )
}

import { SocialLogin } from './social-login'
import { Heading } from '@components/gassan-ui/Typography/Heading'
import InlineNotification from '@components/inline-notification'
import { LoginForm } from '@components/login-form'
import { cn } from '@lib/cn'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type LoginLoginSectionProps = {
  isPasswordReset: boolean
}

export const LoginLoginSection: FC<LoginLoginSectionProps> = ({ isPasswordReset }) => {
  const { t } = useTranslation('other')
  return (
    <div
      className={cn(
        'pb-8 sm:pb-12 lg:pb-0',
        'border-b-2 border-shade-200 lg:border-b-0 lg:border-r lg:border-r-shade-200',
        'lg:pr-12 xl:pr-24',
      )}
    >
      <Heading variant="h3" as="h3">
        {t('logIntoAccount')}
      </Heading>
      <AnimatePresence>
        {isPasswordReset && (
          <InlineNotification
            variant="success"
            title={t('passwordResetLogIn')}
            className="mb-6 md:mb-8"
          />
        )}
      </AnimatePresence>
      <div>
        <SocialLogin />
        <LoginForm />
      </div>
    </div>
  )
}
